import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen1 } from '../../../components/images/support/getting-started/Screen1.generated';
import { Screen2 } from '../../../components/images/support/getting-started/Screen2.generated';
import { Screen3 } from '../../../components/images/support/getting-started/Screen3.generated';
import { Screen4 } from '../../../components/images/support/getting-started/Screen4.generated';
import { Screen5a } from '../../../components/images/support/getting-started/Screen5a.generated';
import { Screen5 } from '../../../components/images/support/getting-started/Screen5.generated';
import { Screen6 } from '../../../components/images/support/getting-started/Screen6.generated';
import { Screen6a } from '../../../components/images/support/getting-started/Screen6a.generated';
import { Screen7 } from '../../../components/images/support/getting-started/Screen7.generated';
import { Screen8 } from '../../../components/images/support/getting-started/Screen8.generated';
import { Screen9 } from '../../../components/images/support/getting-started/Screen9.generated';
import { Screen10 } from '../../../components/images/support/getting-started/Screen10.generated';
import { Screen11 } from '../../../components/images/support/getting-started/Screen11.generated';
import { Screen12 } from '../../../components/images/support/getting-started/Screen12.generated';
import { Screen13a } from '../../../components/images/support/getting-started/Screen13a.generated';
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "create-a-workflow-️"
    }}>{`Create a Workflow ⚡️`}</h1>
    <p>{`First lets setup a Workflow. In this example we will set up a very common payroll Workflow.
The goal will be to create a Workflow to get an employee to onboard for an organisation. `}</p>
    <p>{`This Workflow will have the following steps for the employee:`}</p>
    <ol>
      <li parentName="ol">{`Complete a user profile`}</li>
      <li parentName="ol">{`Complete a Tax File Number Declaration`}</li>
      <li parentName="ol">{`Complete a Standard Super Choice form`}</li>
      <li parentName="ol">{`Supply their bank account details`}</li>
      <li parentName="ol">{`Provide them with a link to the Fair Work Information Statement`}</li>
    </ol>
    <p>{`Let's get started`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the `}<strong parentName="li">{`Workflows`}</strong>{` menu.`}</li>
    </ol>

    <Screen1 mdxType="Screen1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Create`}</strong>{`.`}</li>
    </ol>

    <Screen2 mdxType="Screen2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Give the Workflow a title in this case we can use `}<strong parentName="li"><em parentName="strong">{`Payroll`}</em></strong>{`. Then click `}<strong parentName="li">{`Add task`}</strong>{`.`}</li>
    </ol>

    <Screen3 mdxType="Screen3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Select `}<strong parentName="li">{`User Profile`}</strong>{`. It should appear in the task pane. This shows us the data that the task will collect.`}</li>
    </ol>

    <Screen4 mdxType="Screen4" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Add task`}</strong>{`.`}</li>
    </ol>

    <Screen5a mdxType="Screen5a" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Select `}<strong parentName="li">{`TFN declaration`}</strong>{`.`}</li>
    </ol>

    <Screen5 mdxType="Screen5" />
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Add task`}</strong>{` then click `}<strong parentName="li">{`Super Choice`}</strong>{`.`}</li>
    </ol>

    <Screen6 mdxType="Screen6" />
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Nominate the default superannuation fund for the employer.`}</li>
    </ol>

    <Screen6a mdxType="Screen6a" />
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Add task`}</strong>{` then click `}<strong parentName="li">{`Bank account`}</strong>{`.`}</li>
    </ol>

    <Screen7 mdxType="Screen7" />
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`Now we will create our own custom task to provide the employee with some information.
In this example we will create a task that supplies the employee with a link to the Fair Work Information Statement. Click `}<strong parentName="li">{`Add task`}</strong>{` then click `}<strong parentName="li">{`Form`}</strong>{`.`}</li>
    </ol>

    <Screen8 mdxType="Screen8" />
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Add section`}</strong>{` then click `}<strong parentName="li">{`Text block`}</strong></li>
    </ol>

    <Screen9 mdxType="Screen9" />
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`Add the title `}<strong parentName="li"><em parentName="strong">{`Fair Work Statement`}</em></strong>{`. Copy the following text and paste it in the task just like the image.`}</li>
    </ol>
    <p><em parentName="p">{`Employers are required to give every new employee the Fair Work Information Statement (the FWIS) before, or as soon as possible, when the employee commences employment.
Click here to view the Fair Work Information Statement.`}</em>{` `}</p>
    <p>{`We can create a link to the site. Highlight the word `}<strong parentName="p"><em parentName="strong">{`here`}</em></strong>{` and then click the link 🔗 button in the toolbar.`}</p>

    <Screen10 mdxType="Screen10" />
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol">{`Now copy the following url and paste it into the field and click `}<strong parentName="li">{`Save`}</strong>{`.`}</li>
    </ol>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.fairwork.gov.au/employee-entitlements/national-employment-standards/fair-work-information-statement"
        }}>{`https://www.fairwork.gov.au/employee-entitlements/national-employment-standards/fair-work-information-statement`}</a></em>{` `}</p>

    <Screen11 mdxType="Screen11" />
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Add form`}</strong>{`.`}</li>
    </ol>

    <Screen12 mdxType="Screen12" />
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Save`}</strong>{` to save the Workflow. We always have to save after we add or update tasks.`}</li>
    </ol>

    <Screen13a mdxType="Screen13a" />
    <h3 {...{
      "id": "great-job-you-have-created-your-first-workflow-"
    }}>{`Great Job you have created your first Workflow 🎉`}</h3>
    <h3 {...{
      "id": "now-lets-create-our-first-postion-in-the-next-step"
    }}>{`Now lets create our first postion in the next step`}</h3>

    <SupportFooter linkTo="/support/getting-started/create-a-position" linkText="Create a position" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      